import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SocialIcons from '../SocialIcons';
import './styles.scss';

type Props = {
  newClass?: string;
  handleClick?: () => void;
  isheaderModal?: boolean;
};

const Footer: FC<Props> = ({ newClass, handleClick, isheaderModal }) => {
  const { t } = useTranslation();
  const year = new Date().getFullYear();

  return (
    <footer className={`footer-container  ${newClass}`}>
      <div className="footer-left-block">
        <Link to="/">
          <img src="/images/logo.svg" className="footer-logo" alt="TouRest.online" />
        </Link>
      </div>

      {!isheaderModal && (
        <div className="footer-navigation">
          <Link to={`/about`} className="footer-nav" onClick={handleClick}>
            {t('about')}
          </Link>
          <Link to={`/privacy`} className="footer-nav" onClick={handleClick}>
            {t('privacyPolicy')}
          </Link>
          <Link className="footer-nav" to={`/blog`}>
            {t('blog')}
          </Link>
          <Link className="footer-nav" to={`/referrer`}>
            {t('becomePartner')}
          </Link>
          <Link className="footer-nav" to={`/rates`}>
            {t('rates')}
          </Link>
        </div>
      )}
      <div className="footer-right-block">
        <SocialIcons className="footer-icon" />
        <p className="footer-text">
          {t('copyright')} &copy; {year}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
